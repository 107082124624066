import React from "react";
import { useTranslation } from "react-i18next";

const DataView = ({ label, value }) => {
  const { t } = useTranslation();

  const displayValue =
    typeof value === "boolean"
      ? value
        ? t("Yes")
        : t("No")
      : value || "--";

  return (
    <div className="w-full lg:w-1/2">
      <label className="block mb-0 text-sm font-normal text-gray-600">
        {t(`${label}`)}
      </label>
      <p className="mb-5 text-lg font-semibold text-gray-800">
        {displayValue}
      </p>
    </div>
  );
};

export default DataView;
