import React, { useState, useEffect, useCallback } from "react";
import APIServices from "../../../httpServices/httpServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import DataView from "../../common/viewDetail/DataView";
import DeleteModal from "../../common/modals/DeleteModal";
import CustomImage from "../../common/CustomImage";
import moment from "moment";
import UserCompletedReadingHistory from "./UserCompletedReadingHistory";

const ViewFrontUsers = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userCompletedReadingGroups, setUserCompletedReadingGroups] = useState([]);

  useEffect(() => {
    if (id) {
      getDetailView();
      getUserCompletedReadingGroups();
    }
    // eslint-disable-next-line
  }, []);

  // Fetch User Data
  const getDetailView = useCallback(async () => {
    const { data, success } = await new APIServices(`user/${id}`).get();
    if (success) {
      setUserData(data);
    }
  }, [id]);

  const getUserCompletedReadingGroups = useCallback(async () => {
    const { data, success } = await new APIServices(`user/completed-reading-groups/${id}`).get();
    if (success) {
      setUserCompletedReadingGroups(data);
    }
  }, [id]);

  const handleShowDeleteModal = (id) => {
    setShowModal(true);
  };

  const handleDeleteUser = async () => {
    const { message, success } = await new APIServices(`user/${id}`).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      navigate("/users");
    }
  };

  const {
    _id,
    firstName,
    lastName,
    email,
    dob,
    aliasName,
    gender,
    phone,
    adress,
    countryCode,
    countryCallingCode,
    timezone,
    displayCurrency,
    isVerified,
    status,
  } = userData;


  return (
    <>
      <div className="max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        <div className="flex gap-x-4">
          <div className="flex-1">
            <div className="font-bold text-2xl" name="">
              {firstName} {lastName}
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div
                    className={`rounded-full w-3 h-3 bg-green-700 mr-2 ${status === "Active" ? "bg-green-700" : "bg-red-700"
                      }`}
                  ></div>
                  <span className="text-sm"> {status} </span>
                </div>

                {status === "Inactive" && (
                  <button
                    onClick={() => handleShowDeleteModal(_id)}
                    className="flex items-center px-4 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-600 mb-5"
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
            <div className="mb-3 text-gray-500">
              <span className="text-base">{email}</span>
            </div>
            <div className="grid grid-cols-3 gap-x-2">
              <div className="flex flex-col">
                <span className="block mb-0 text-sm text-gray-600 font-normal">

                  Date of Birth
                </span>
                <span className="font-bold">
                  {dob ? moment(dob, moment.ISO_8601).format("LL") : "--"}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="block mb-0 text-sm text-gray-600 font-normal">
                  phone{" "}
                </span>
                <span className="mb-5 text-base font-semibold text-gray-800">
                  {phone ? phone : "--"}{" "}
                </span>
              </div>


            </div>


          </div>
        </div>

        <h2 className="font-bold text-lg mt-2 mb-2"> Personal Information </h2>
        <div className="shadow bg-white border-gray-500 p-8 rounded mt-2">
          <div className="flex flex-wrap">
            <DataView label="isVerified" value={isVerified} />
            <DataView label="countryCode" value={countryCode} />
            <DataView label="phone" value={phone} />
            <DataView label="gender" value={gender} />
            <DataView label="aliasName" value={aliasName} />
            <DataView label="adress" value={adress} />
            <DataView label="countryCallingCode" value={countryCallingCode} />
            <DataView label="timezone" value={timezone} />
            <DataView label="displayCurrency" value={displayCurrency} />

          </div>
        </div>

        {showModal && (
          <DeleteModal handleDelete={handleDeleteUser} close={setShowModal} />
        )}
      </div>
      <UserCompletedReadingHistory userData={userCompletedReadingGroups} />
    </>
  );
};

export default ViewFrontUsers;
