import React from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value, debounce]);

  return (
    <input
      {...props}
      value={value || ""}
      onChange={(e) => setValue(e.target.value)}
      className="border p-2 rounded"
    />
  );
}

function Filter({ column, onFilterChange }) {
  const columnFilterValue = column.getFilterValue();

  if (column.columnDef.meta?.disableFilter) return null;

  return (
    <DebouncedInput
      type="text"
      value={columnFilterValue || ""}
      onChange={(value) => {
        column.setFilterValue(value);
        // Only call onFilterChange if the input has at least 4 characters or is empty
        if (value.length >= 4 || value === "") {
          onFilterChange(column.id, value);
        }
      }}
      placeholder={`Search ${column.columnDef.header}`}
      className="w-36 border rounded p-1"
    />
  );
}

export default function DataTable({ columns, data, onFilterChange }) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), // Enable client-side sorting
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div className="pt-4 overflow-x-auto">
      <table className="table-auto border-collapse border border-gray-300 w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="border p-2">
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getCanFilter() && (
                    <div className="mt-2">
                      <Filter column={header.column} onFilterChange={onFilterChange} />
                    </div>
                  )}
                  {header.column.getCanSort() && (
                    <button
                      onClick={header.column.getToggleSortingHandler()}
                      className="ml-2"
                    >
                      {header.column.getIsSorted() === "asc" ? "🔼" : "🔽"}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="border p-2">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}