import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../common/CustomTable";
import APIServices from "../../../httpServices/httpServices";
import DeleteModal from "../../common/modals/DeleteModal";
import { emitSuccessToast } from "../../common/toast/ToastContainer";
import TablePagination from "../../common/table/TablePagination";
import TableEntry from "../../common/table/TableEntry";
import TableSkeleton from "../../common/skeleton/TableSkeleton";
import { LIMIT } from "../../../utilities/constant";
import DataTable from "../../common/DataTable";


const FrontUsersList = () => {
  const navigate = useNavigate();
  const [filterState, setFilterState] = useState({});
  const [debouncedFilterState, setDebouncedFilterState] = useState({});
  // Table headings for verified and unverified users
  const tableHeading = [
    {
      accessorKey: 'firstName',
      header: 'First Name',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'lastName',
      header: 'Last Name',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'email',
      header: 'Email',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'isVerified',
      header: 'Verified',
      cell: (info) => (info.getValue() ? 'Yes' : 'No'),
      meta: { disableFilter: true }

    },
    {
      accessorKey: 'countryCode',
      header: 'Country Code',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'readingCompletedTests',
      header: 'Completed Tests',
      // meta: { filterVariant: 'range' },
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'readingAverageScore',
      header: 'Avg. Score',
      cell: (info) => `${info.getValue()}`,
      meta: { disableFilter: true }
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: (info) => info.getValue(),
      meta: { disableFilter: true }

    },
    {
      accessorKey: 'action', header: 'Action', cell: ({ row }) => renderActions(row),
      meta: { disableFilter: true }
    },
  ];

  const unverifiedTableHeading = [
    { accessorKey: 'firstName', header: 'First Name' },
    { accessorKey: 'lastName', header: 'Last Name' },
    { accessorKey: 'email', header: 'Email' },
    { accessorKey: 'phone', header: 'Phone' },
    { accessorKey: 'isVerified', header: 'Verified', meta: { disableFilter: true } },
    { accessorKey: 'countryCode', header: 'Country Code' },
    {
      accessorKey: 'action', header: 'Action', cell: ({ row }) => renderActions(row),
      meta: { disableFilter: true }
    },
  ];

  const [customerList, setCustomerList] = useState([]);
  const [unverifiedUserList, setUnverifiedUserList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const selectedCustomerId = null;

  // Pagination and search state
  const [searchTxt, setSearchTxt] = useState('');
  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesNumber, setTotalPageNumber] = useState(0);
  const [numberOfTotalData, setNumberOfTotalData] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [currentUnverifiedPage, setCurrentUnverifiedPage] = useState(1);
  const [totalPagesNumberUnverified, setTotalPageNumberUnverified] = useState(0);
  const [numberOfTotalUnverifiedData, setNumberOfTotalUnverifiedData] = useState(0);
  const [isLoadingUnverified, setIsLoadingUnverified] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedFilterState(filterState);
    }, 500); // Adjust debounce time as needed

    return () => clearTimeout(timeout);
  }, [filterState]);

  useEffect(() => {
    let timeout;

    // Only trigger API call if search text has at least 4 characters or is empty
    if (searchTxt.length >= 4 || searchTxt === "") {
      timeout = setTimeout(() => getListOfUsers(), 500); // Debounce API call
    }

    return () => clearTimeout(timeout);
  }, [searchTxt, currentPage,]);

  useEffect(() => {
    let timeout;
    if (searchTxt) {
      timeout = setTimeout(() => getListOfUnverifiedUsers(), 1500);
    } else {
      getListOfUnverifiedUsers();
    }
    return () => clearTimeout(timeout);
  }, [searchTxt, currentUnverifiedPage]);

  const handleFilterChange = (columnId, value) => {
    setFilterState((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  };


  const getListOfUsers = async () => {
    setIsLoading(true);
    const { data, success, total, totalPages } = await new APIServices('user/list').post({
      page: currentPage,
      limit,
      isVerified: true,
      filters: debouncedFilterState, // Send filters to the API
      search: searchTxt
    });


    if (success) {
      setIsLoading(false);
      setCustomerList(data);
      setTotalPageNumber(totalPages || 0);
      setNumberOfTotalData(total || 0);
    }
  };



  const getListOfUnverifiedUsers = async () => {
    setIsLoadingUnverified(true);
    const { data, success, total, totalPages } = await new APIServices('user/list').post({
      page: currentUnverifiedPage,
      limit: limit,
      search: searchTxt,
      isVerified: false,
    });

    if (success) {
      setIsLoadingUnverified(false);
      setUnverifiedUserList(data);
      setTotalPageNumberUnverified(totalPages || 0);
      setNumberOfTotalUnverifiedData(total);
    }
  };

  const handleDeleteUser = async () => {
    const { message, success } = await new APIServices(`user/${selectedCustomerId}`).delete();
    if (success) {
      setShowModal(false);
      emitSuccessToast(message);
      getListOfUsers();
    }
  };

  const handleEditUser = (id) => navigate(`/users/edit/${id}`);
  const handleView = (id) => navigate(`/users/view/${id}`);
  const handleTableSearch = (val) => setSearchTxt(val);

  const renderActions = (row) => (
    <div className="flex gap-2">
      <button onClick={() => handleView(row.original._id)} className="text-blue-500">View</button>
      {/* <button onClick={() => handleEditUser(row.original._id)} className="text-green-500">Edit</button> */}
    </div>
  );

  return (
    <section className="dark:bg-gray-800">
      <div className="max-w-10xl px-4 py-4 mx-auto lg:py-8 md:px-6">
        {/* Verified Users Table */}
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry title="List of Users" handleTableSearch={handleTableSearch} />
              <DataTable columns={tableHeading} data={customerList} onFilterChange={handleFilterChange} />
              <TablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPagesNumber={totalPagesNumber}
                numberOfTotalData={numberOfTotalData}
                limit={limit}
              />
            </>
          )}
        </div>

        {/* Unverified Users Table */}
        <div className="pt-4 rounded shadow bg-white dark:bg-gray-900 mt-4">
          {isLoadingUnverified ? (
            <TableSkeleton />
          ) : (
            <>
              <TableEntry title="List of Unverified Users" handleTableSearch={handleTableSearch} />
              <DataTable columns={unverifiedTableHeading} data={unverifiedUserList} onFilterChange={handleFilterChange} />
              <TablePagination
                currentPage={currentUnverifiedPage}
                setCurrentPage={setCurrentUnverifiedPage}
                totalPagesNumber={totalPagesNumberUnverified}
                numberOfTotalData={numberOfTotalUnverifiedData}
                limit={limit}
              />
            </>
          )}
        </div>
      </div>

      {showModal && <DeleteModal handleDelete={handleDeleteUser} close={setShowModal} />}
    </section>
  );
};

export default FrontUsersList;
